import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import img1 from '../../assets/popup/sge icef berlin.png'
import { Link } from 'react-router-dom';
import './NewsPopup.css'

const NewsPopup = () => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);



  return (
    <div>
      <Modal show={showModal} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" >
            {/* <small className='modalTitle'><Link to='/events'>Click Here For More Info</Link></small> */}
            
            {/* <small className='modalTitle'>Wishing you a year filled with knowledge, growth, and success! <b>Happy New Year</b> from <b>Shabuj Global Education</b>! 🎉📚 #NewBeginnings  </small> */}

            {/* <Link to='registration'><button class="glow-on-hover" type="button">Register Now</button></Link> */}



          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='w-100' src={img1} alt="modal image" />

          {/* <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FShabujGlobaleducationuk%2Fvideos%2F922637778849550%2F&show_text=false&width=560&t=0" width="100%" height="314"  style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}
          {/* <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FShabuj.india%2Fvideos%2F348298757560213%2F&show_text=false&width=267&t=0" width="267" height="476" style={{ border: 'none', overflow: 'hidden' }}  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default NewsPopup;



/*

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import img1 from '../../assets/events/event1.jpg'
const NewsPopup = () => {
    const [showModal, setShowModal] = useState(true);
    const handleClose = () => setShowModal(false);

// i've called it on Main.js in layout for future news
    return (
        <div>
            <Modal show={showModal} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <img className='w-100' src={img1} alt="modal image" />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default NewsPopup;


*/


/*

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import img1 from '../../assets/events/event1.jpg'

const NewsPopup = () => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  return (
    <div>
      <Modal show={showModal} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='w-100' src={img1} alt="modal image" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewsPopup;


*/
