import React from 'react';
import { Container } from 'react-bootstrap';
import './EventCarousal.css'
import Carousel from 'react-bootstrap/Carousel';
import CountdownTimerOne from '../../Shared/CountdownTimer/CountdownTimerOne/CountdownTimerOne';
import CountdownTimerTwo from '../../Shared/CountdownTimer/CountdownTimerTwo/CountdownTimerTwo';
import CountdownTimerThree from '../../Shared/CountdownTimer/CountdownTimerThree/CountdownTimerThree';

// import event6 from '../../assets/events/event6.jpg'

// import event4 from '../../assets/events/event4.jpg'
// import event5 from '../../assets/events/event5.jpg'
// import event7 from '../../assets/events/event7.jpg'
// import event8 from '../../assets/events/event8.jpg'
// import event9 from '../../assets/events/event9.jpg'
import event10 from '../../assets/events/event10.png'
import event12 from '../../assets/events/event12.jpg'
import event13 from '../../assets/events/event13.jpg'
import event14 from '../../assets/events/event14.jpg'
// import event15 from '../../assets/events/event19.jpg'
import event16 from '../../assets/events/event21.jpg'
import event17 from '../../assets/events/event23.jpg'
import event18 from '../../assets/events/event25.jpg'
import event19 from '../../assets/events/event30.jpg'
import event20 from '../../assets/events/event31.jpg'
import event21 from '../../assets/events/event32.jpeg'
import event24 from '../../assets/events/event35.jpeg'
import event25 from '../../assets/events/event36.jpg'
import event26 from '../../assets/events/event40.jpg'
import event27 from '../../assets/events/event42.png'
import event28 from '../../assets/events/event44.jpg'
import event29 from '../../assets/events/event45.png'
import event30 from '../../assets/events/event51.jpg'
import event31 from '../../assets/events/event53.jpg'
import event32 from '../../assets/events/event60.jpeg'
import event33 from '../../assets/events/event57.jpeg'
import event34 from '../../assets/events/event61.png'
import event35 from '../../assets/events/event64.png'
import event36 from '../../assets/events/event65.png'
import event37 from '../../assets/events/event72.png'
import event38 from '../../assets/events/event73.png'
import event39 from '../../assets/events/event74.jpg'

import { Link } from 'react-router-dom';



const EventCarousal = () => {
    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };


    return (
        <Container className='eventCaroMarginTop ' >
            <h1 className='text-center eventHeading'>Upcoming Events</h1>
            <div className='text-center'>
                <Link to='/registration'>
                    <a href="" rel="noreferrer">
                        <button style={{ width: '109px' }} class="glow-on-hover" type="button" > REGISTER NOW </button>
                    </a>
                </Link>
            </div>

            <Carousel variant="dark" className='mt-2' indicators={false}>



                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event37}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>

                        {/* <Link to='/registration'>
                            <a href="" rel="noreferrer"> 
                                <button class="glow-on-hover" type="button" > APPLY NOW </button>
                            </a>
                        </Link> */}
                        {/* <CountdownTimerOne /> */}
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event38}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>

                        {/* <Link to='/registration'>
                            <a href="" rel="noreferrer"> 
                                <button class="glow-on-hover" type="button" > APPLY NOW </button>
                            </a>
                        </Link> */}
                        {/* <CountdownTimerOne /> */}
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event39}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>

                        {/* <Link to='/registration'>
                            <a href="" rel="noreferrer"> 
                                <button class="glow-on-hover" type="button" > APPLY NOW </button>
                            </a>
                        </Link> */}
                        {/* <CountdownTimerOne /> */}
                    </Carousel.Caption>
                </Carousel.Item>



                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event26}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item> */}




                {/* 
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event19}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item> */}



                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event14}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item> */}

                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event16}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item> */}



                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event4}
                        alt="First slide"
                        // height="600"
                        // width='1200'
                    />
                    <Carousel.Caption>
                        <a href="https://forms.gle/AaxuE7rgLKp6rHwy5" target={'_blank'} rel="noreferrer">
                            <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item> */}

                {/* 
                <Carousel.Item>
                    <img
                        className="d-block w-100 "
                        src={event5}
                        alt="First slide"
                        // height="600"
                        // width='1200'
                    />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item> */}

                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event6}
                        alt="Second slide"
                        // height="600"
                        // width='1200'
                    />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item> */}

            </Carousel>
        </Container>
    );
};

export default EventCarousal;


/*

//previous carousel event img



               <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event27}
                        alt="First slide"
                    // height="600"
                    // width='1200'
                    />
                    <Carousel.Caption>


                        </Carousel.Caption>
                        </Carousel.Item>
        
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={event25}
                                alt="First slide"
                            // height="600"
                            // width='1200'
                            />
                            <Carousel.Caption>
        

                            </Carousel.Caption>
                        </Carousel.Item>
        
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={event21}
                                alt="First slide"
                            // height="600"
                            // width='1200'
                            />
                            <Carousel.Caption>
        

                            </Carousel.Caption>
                        </Carousel.Item>
        
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={event24}
                                alt="First slide"
                            // height="600"
                            // width='1200'
                            />
                            <Carousel.Caption>
        

                            </Carousel.Caption>
                        </Carousel.Item>
        
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={event19}
                                alt="First slide"
                            // height="600"
                            // width='1200'
                            />
                            <Carousel.Caption>
        

                            </Carousel.Caption>
                        </Carousel.Item>
        




*/






/*
//original code

import React from 'react';
import { Container } from 'react-bootstrap';
import './EventCarousal.css'
import Carousel from 'react-bootstrap/Carousel';
import CountdownTimerOne from '../../Shared/CountdownTimer/CountdownTimerOne/CountdownTimerOne';
import CountdownTimerTwo from '../../Shared/CountdownTimer/CountdownTimerTwo/CountdownTimerTwo';
import CountdownTimerThree from '../../Shared/CountdownTimer/CountdownTimerThree/CountdownTimerThree';

import event6 from '../../assets/events/event6.jpg'
import event4 from '../../assets/events/event4.jpg'
import event5 from '../../assets/events/event5.jpg'
import event7 from '../../assets/events/event7.jpg'
import event8 from '../../assets/events/event8.jpg'
import event9 from '../../assets/events/event9.jpg'



const EventCarousal = () => {
    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };

    
    return (
        <Container className='eventCaroMarginTop '>
            <h1 className='text-center eventHeading'>Upcoming Events</h1>
            <Carousel variant="dark" className='mt-2'>
                <Carousel.Item>
                    <img
                        className="d-block w-100 "
                        src={event9}
                        alt="First slide"
                        height="600"
                        width='1200'
                    />
                    <Carousel.Caption>
                        <a href="https://forms.gle/AaxuE7rgLKp6rHwy5" target={'_blank'} rel="noreferrer">
                            <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                        </a>
                        <CountdownTimerOne />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 "
                        src={event7}
                        alt="First slide"
                        height="600"
                        width='1200'
                    />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                        </a>
                        <CountdownTimerTwo />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={event8}
                        alt="Second slide"
                        height="600"
                        width='1200'
                    />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                        </a>
                        <CountdownTimerThree />
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Container>
    );
};

export default EventCarousal;

*/




/*
//aita hoise but vul ase MODAL test


import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import './EventCarousal.css'
import Carousel from 'react-bootstrap/Carousel';
import CountdownTimerOne from '../../Shared/CountdownTimer/CountdownTimerOne/CountdownTimerOne';
import CountdownTimerTwo from '../../Shared/CountdownTimer/CountdownTimerTwo/CountdownTimerTwo';
import CountdownTimerThree from '../../Shared/CountdownTimer/CountdownTimerThree/CountdownTimerThree';

import event6 from '../../assets/events/event6.jpg'
import event4 from '../../assets/events/event4.jpg'
import event5 from '../../assets/events/event5.jpg'
import event7 from '../../assets/events/event7.jpg'
import event8 from '../../assets/events/event8.jpg'
import event9 from '../../assets/events/event9.jpg'



const EventCarousal = () => {
    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClose = () => setShowModal(false);

    return (
        <Container className="eventCaroMarginTop ">
            <h1 className="text-center eventHeading">Upcoming Events</h1>
            <Carousel variant="dark" className="mt-2">
               
                <Carousel.Item onClick={() => setShowModal(true)}>
                    <img className="d-block w-100" src={event9} alt="Second slide" height="600" width="1200" />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button className="glow-on-hover" type="button" disabled >
                                APPLY NOW
                            </button>
                        </a>
                        <CountdownTimerOne />
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item onClick={() => setShowModal(true)}>
                    <img className="d-block w-100" src={event8} alt="Second slide" height="600" width="1200" />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button className="glow-on-hover" type="button" disabled >
                                APPLY NOW
                            </button>
                        </a>
                        <CountdownTimerTwo />
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item onClick={() => setShowModal(true)}>
                    <img className="d-block w-100" src={event5} alt="Second slide" height="600" width="1200" />
                    <Carousel.Caption>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button className="glow-on-hover" type="button" disabled >
                                APPLY NOW
                            </button>
                        </a>
                        <CountdownTimerOne />
                    </Carousel.Caption>
                </Carousel.Item>
       
            </Carousel>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button className="glow-on-hover" type="button" disabled >
                                APPLY NOW
                            </button>
                        </a>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className="img-fluid" src={event9} alt="Modal Image" />
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button className="glow-on-hover" type="button" disabled >
                                APPLY NOW
                            </button>
                        </a>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className="img-fluid" src={event8} alt="Modal Image" />
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                            <button className="glow-on-hover" type="button" disabled >
                                APPLY NOW
                            </button>
                        </a>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className="img-fluid" src={event5} alt="Modal Image" />
                </Modal.Body>
            </Modal>
      
        </Container>
    );
};

export default EventCarousal;




*/




























































