import React, { useEffect, useState } from 'react';

const CountdownTimerOne = () => {
  const countdownDate = new Date("2024-10-16T23:59:59").getTime(); // raat 12tar countdown
  // Happening now - dekhanor jonno [start and end time dite home 24h er formeter time hishebe]
  const specificTimeFrameStart = new Date("2024-10-16T10:00:00").getTime(); //24h
  const specificTimeFrameEnd = new Date("2024-10-16T20:30:00").getTime(); //24h

// y/m/d


  const [isHappeningNow, setIsHappeningNow] = useState(false);
  const [isOver, setIsOver] = useState(false);

  const calculateTimeRemaining = () => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const isWithinSpecificTimeFrame = now >= specificTimeFrameStart && now <= specificTimeFrameEnd;

      if (isWithinSpecificTimeFrame) {
          setIsHappeningNow(true);
          setIsOver(false);
      } else if (distance <= 0) {
          setIsHappeningNow(false);
          setIsOver(true);
      } else {
          setIsHappeningNow(false);
          setIsOver(false);
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          setDays(days);
          setHours(hours);
          setMinutes(minutes);
          setSeconds(seconds);
      }
  };

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
      const interval = setInterval(calculateTimeRemaining, 1000);

      return () => clearInterval(interval);
  }, []);

  return (
      <div className="mt-3">
          {isHappeningNow ? (
              <span
                  style={{ fontWeight: '800' }}
                  className="text-white bg-success text-center rounded mt-3 font-weight-bold p-1"
              >
                  Happening Now
              </span>
          ) : isOver ? (
              <span
                  style={{ fontWeight: '800' }}
                  className="text-white bg-danger text-center rounded mt-3 font-weight-bold p-1"
              >
                  Registration Over
              </span>
          ) : (
              <span
                  style={{ fontWeight: '800' }}
                  className="text-white bg-primary text-center rounded mt-3 font-weight-bold p-1"
              >
                  {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
              </span>
          )}
      </div>
  );
};

export default CountdownTimerOne;








/*



// 'hapenning Now add korar ager code





import React, { useEffect, useState } from 'react';

const CountdownTimerOne = () => {
  const countdownDate = new Date("2023-03-18T23:59:59").getTime();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance <= 0) {
        setIsOver(true);
        clearInterval(interval);
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownDate]);

  return (
    <div className="mt-3">
      {isOver ? (
        <span
          style={{ fontWeight: '800' }}
          className="text-white bg-danger text-center rounded mt-3 font-weight-bold"
        >
          Registration Over
        </span>
      ) : (
        <span
          style={{ fontWeight: '800' }}
          className="text-white bg-primary text-center rounded mt-3 font-weight-bold"
        >
          {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
        </span>
      )}
    </div>
  );
};

export default CountdownTimerOne;















*/