import React, { useEffect } from 'react';
import './Footer.css'
import logo from '../../assets/shabujLogo.png'
import { BiBeenHere } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";
import { BiPhoneCall } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { AiFillYoutube } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';

const Footer = () => {
    //icef code
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www-cdn.icef.com/scripts/iasbadgeid.js';
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);



    return (
        <div>
            <footer class="new_footer_area bg_color">
                <div class="new_footer_top">
                    <div className="container">
                        <div className="row">
                            <div className='col-md-4 footerColor'>
                                <h5 className='text-center'><BiBeenHere />Head Office</h5>
                                <h6 className='text-center'>94A Whitechapel HighStreet London E17RA UK.
                                </h6>
                            </div>
                            <div className='col-md-4 footerColor'>
                                <h5 className='text-center'><BiPhoneCall />Contact Us</h5>
                                <h6 className='text-center'><a className='myAlink' href="tel:(+44)02070929172">(+44) 02070929172</a></h6>
                            </div>
                            <div className='col-md-4 footerColor'>
                                <h5 className='text-center'><BiMailSend />Mail</h5>
                                <h6 className='text-center'><a className='myAlink' itemprop="email" href="mailto:info@shabujglobal.com">info@shabujglobal.com</a></h6>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                {/* icef code */}
                                <span id="iasBadge" data-account-id="6238"></span>


                                {/* icef real html code: then converted to jsx */}
                                {/* <span id='iasBadge' data-account-id='6238'></span><script async defer crossorigin="anonymous" src="https://www-cdn.icef.com/scripts/iasbadgeid.js"></script> */}
                                
                                {/* <div class="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInLeft' }}>
                                    <h3 class="f-title f_600 t_color f_size_18"> “OUR STUDENTS ARE OUR REFERENCE”</h3>
                                    
                                </div> */}
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInLeft' }}>
                                    <h3 class="f-title f_600 t_color f_size_18">Useful Links</h3>
                                    <ul class="list-unstyled f_list">
                                        <Link to="/ChannelPartner">Channel Partner</Link> <br />
                                        <Link to='/career'><a>Career</a></Link> <br />
                                        <li><a href="https://membership.shabujglobal.com/" target='_blank' rel="noreferrer">Become A Member</a></li>
                                        {/* <Link to='/career'>Privacy & Policy</Link> <br /> */}


                                        <li><a href="#">HS Language IELTS</a></li>
                                        <li><a href="https://webmail.names.co.uk/" target='_blank' rel="noreferrer">webmail</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s" style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInLeft' }}>
                                    <h3 class="f-title f_600 t_color f_size_18">Get Help</h3>
                                    <ul class="list-unstyled f_list">
                                        <Link to='/scholarship'><a>Scholarship</a></Link>
                                        <li><a href="#">CSR</a></li>
                                        <li><a href="https://www.nhs.uk/" target='_blank' rel="noreferrer">NHS</a></li>
                                        <li><a href="https://www.gov.uk/government/organisations/uk-visas-and-immigration" target='_blank' rel="noreferrer">UKVI</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s" style={{ visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeInLeft' }}>
                                    <h3 class="f-title f_600 t_color f_size_18">Find Us</h3>
                                    <div class="f_social_icon">
                                        <a href="https://www.facebook.com/ShabujGlobaleducationuk/" target="_blank" rel="noreferrer" ><FaFacebook /></a>
                                        <a href="https://www.linkedin.com/company/shabuj-global-education/" target="_blank" rel="noreferrer" ><FaLinkedin /></a>
                                        <a href="https://twitter.com/shabujglobaluk" target="_blank" rel="noreferrer" ><FaSquareXTwitter /></a>
                                        <a href="https://youtube.com/@shabujglobaleducation1568" target="_blank" rel="noreferrer" ><AiFillYoutube /></a>
                                        <a href="https://instagram.com/shabujglobaleducation" target="_blank" rel="noreferrer" ><AiOutlineInstagram /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer_bg">
                        <div class="footer_bg_one"></div>
                        <div class="footer_bg_two"></div>
                        <div class="footer_bg_three"></div>
                    </div>
                </div>
                <div class="footer_bottom">
                    <div class="container text-center">
                        <p>Copyright © 2024, All Right Reserved <a href="https://www.shabujglobal.com/">Shabuj Global Education</a></p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;